import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { formatHtmlTitle } from 'utils/globals'
import { Login } from 'components/templates/login'
import { useUser } from 'hooks/useUser'
import Head from 'next/head'

const pageTitle = 'Open Loot Marketplace'
const pageDescription = 'Sign in or create an account to get started on Open Loot.'

const LoginPage = () => {
  const router = useRouter()
  const { data: user, status } = useUser()

  useEffect(() => {
    if (status === 'success' && user) {
      router.replace('/')
    }
  }, [router, user, status])

  return (
    <>
      <Head>
        <title key="title">{formatHtmlTitle('Login')}</title>
        <meta key="meta:title" name="title" content={pageTitle} />
        <meta key="meta:description" name="description" content={pageDescription} />
        <meta key="og_title" property="og:title" content={pageTitle} />
        <meta key="og_description" property="og:description" content={pageDescription} />
        <meta key="twitter_title" property="twitter:title" content={pageTitle} />
        <meta key="twitter_description" property="twitter:description" content={pageDescription} />
      </Head>
      <Login />
    </>
  )
}

export default LoginPage
